import { isNotDefined } from '@sgme/fp';
import type { ComponentType, MouseEventHandler, ReactElement } from 'react';
import { Children, cloneElement, isValidElement, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import type { ModalProps as ReactstrapModalProps } from 'reactstrap';

import PresetCashFormModal from '@components/modals/PresetCashFormModal';
import { EditMarginOrTieringModal } from '@pages/fx/FxProductsPage/CashTab/EditMarginOrTieringModal';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { FxProfileChangeReasonsModal } from 'components/modals/FxProfileChangeReasonsModal';
import { CancelValidationModal } from './CancelValidationModal';
import { ClientFormModal } from './ClientFormModal';
import { CustomOrderFormModal } from './CustomOrderFormModal';
import { DeleteClientModal } from './DeleteClientModal';
import { DuplicateClientModal } from './DuplicateClientModal';
import { FxClientsForProfileModal } from './FxClientsForProfileModal';
import { FxEditPoolPresetWarningModal } from './FxEditPoolPresetWarningModal';
import { FxEditStandardPresetWarningModal } from './FxEditStandardPresetWarningModal';
import { FxRenameProfileModal } from './FxRenameProfileModal';
import { FxUnsavedChangesWarningModal } from './FxUnsavedChangesWarningModal';
import { PresetOrderFormModal } from './PresetOrderFormModal';

const MODAL_BY_NAME = {
  FxClientsForProfileModal,
  FxEditPoolPresetWarningModal,
  FxEditStandardPresetWarningModal,
  FxProfileChangeReasonsModal,
  FxUnsavedChangesWarningModal,
  ClientFormModal,
  DuplicateClientModal,
  DeleteClientModal,
  CancelValidationModal,
  EditMarginOrTieringModal,
  FxRenameProfileModal,
  PresetOrderFormModal,
  PresetCashFormModal,
  CustomOrderFormModal,
} as const;

export type ModalName = keyof typeof MODAL_BY_NAME;

type PropsOf<T> = T extends React.ComponentType<infer Props> ? Props : never;
type ModalProps<T extends ModalName> = PropsOf<(typeof MODAL_BY_NAME)[T]>;

export type ReactElementWithOnClick = ReactElement<{
  onClick: MouseEventHandler<HTMLButtonElement>;
}>;

type ModalHandlerProps<Name extends ModalName> = ModalProps<Name> & {
  modalName: Name;
  byPassHandler?: boolean; // TODO: really useful ?
  children: ReactElementWithOnClick;
};

export const ModalHandler = <Name extends ModalName>({
  modalName,
  byPassHandler = false,
  children,
  onClosed,
  ...props
}: ModalHandlerProps<Name>): JSX.Element => {
  const { sgwtWebAnalytics } = useSgwtWidgets();
  const [isOpen, setOpen] = useState(false);

  const innerOnClosed = useCallback(() => {
    if (onClosed) {
      onClosed();
    }
    setOpen(false);
  }, [onClosed]);

  if (byPassHandler) {
    return children;
  }

  const enrichedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      const onClick: MouseEventHandler<HTMLButtonElement> = () => {
        sgwtWebAnalytics?.trackEvent('Common', 'Modal opened', modalName);

        setOpen(true);
      };

      return cloneElement(child as ReactElementWithOnClick, { onClick });
    }

    return child;
  });

  // We lie to typescript to force props in component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Modal = MODAL_BY_NAME[modalName] as ComponentType<ReactstrapModalProps>;

  // language=CSS
  const domNode = document.querySelector('#root-modal');

  if (isNotDefined(domNode)) {
    return children;
  }

  return (
    <>
      {enrichedChildren}

      {isOpen &&
        createPortal(
          <Modal
            isOpen={isOpen}
            onClosed={innerOnClosed}
            toggle={innerOnClosed}
            unmountOnClose
            centered
            returnFocusAfterClose={false}
            modalHeaderClose={<button type="button" className="btn-close" onClick={innerOnClosed} />}
            {...props}
          />,
          domNode,
        )}
    </>
  );
};
