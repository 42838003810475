import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import type { SerializedError } from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';
import { useAddFxOrderClientPoolPresetMutation } from '@services/fx/addFxOrderClientPoolPreset';

import { MarginInput } from 'components/fx/MarginInput';
import { MarginLiquidityProvider } from 'components/fx/MarginLiquidityProvider';
import { AlgoOrderProductMarginUnitSwitch } from 'components/fx/AlgoOrderProductMarginUnitSwitch';
import { LoadingButton } from 'components/LoadingButton';
import { FormInput } from '../Form/FormInput';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { getFxMarginUnit } from '@store/ui/uiSelectors';
import type { FxOrderProfile, OrderProfileColumns } from '@services/fx/model/order';

interface FormValues extends OrderProfileColumns {
  profileName: string;
  'margin-unit': string;
  marginValue: string;
  liquidityProviderDimension: {
    External: string;
    Internal: string;
  };
}

export interface PresetOrderFormModalProps extends ModalProps {
  profilName?: string;
}

export const PresetOrderFormModal = ({ profileName, ...props }: PresetOrderFormModalProps) => {
  const { modalHeaderClose, onClosed } = props;

  const { perimeterKey, productKey, gridDataType, clientBdrLevel, clientBdrId } = useFxUrlParams();

  const [addFxOrderClientPoolPreset, { isLoading }] = useAddFxOrderClientPoolPresetMutation();

  const fxMarginUnit = useSelector(getFxMarginUnit);

  const marginUnit = () => {
    switch (productKey) {
      case 'algo-order-product':
        return fxMarginUnit.algo;
      case 'limit-order-product':
        return fxMarginUnit.limit;
      case 'fixing-order-product':
        return fxMarginUnit.fixing;
      default:
        return fxMarginUnit.limit;
    }
  };

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      profileName,
      marginValue: '',
      liquidityProviderDimension: {},
    },
  });

  const {
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = async ({
    profileName,
    marginValue,
    liquidityProviderDimension: { External: externalMargin, Internal: internalMargin },
  }) => {
    if (isDefined(perimeterKey) && isDefined(productKey)) {
      const creationResponse = await addFxOrderClientPoolPreset({
        perimeterKey,
        productKey,
        profileTypeKey: 'client-pool-profile-type',
        addFxClientPoolPresetPayload: {
          profileName,
          'liquidity-provider-dimension.External.margin-value': externalMargin,
          'liquidity-provider-dimension.Internal.margin-value': internalMargin,
          'margin-unit': marginUnit(),
          'margin-value': marginValue,
        },
        queryParams: {
          gridDataType,
          // we always have values when submitting
          clientBdrLevel: clientBdrLevel!,
          clientBdrId: clientBdrId!,
        },
      });

      const { error, data } = creationResponse as { error: SerializedError; data: FxOrderProfile }; // bad typing of rtk query

      if (isDefined(error)) {
        setError('profileName', { type: 'alreadyExist' }, { shouldFocus: true });
      } else {
        if (isDefined(onClosed)) {
          onClosed();
        }
      }

      if (isDefined(data.profileId)) {
        navigate(`./edit?profileId=${data.profileId}`);
      }
    }
  };

  // Focus the field only when the modal is open, before input is not rendered.
  const onOpened = () => setFocus('profileName', { shouldSelect: true });

  return (
    <Modal data-e2e="preset-form-modal" contentClassName="border" onOpened={onOpened} {...props}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader tag="h3" close={modalHeaderClose}>
            <FormattedMessage id="newClientPoolModal.title" />
          </ModalHeader>

          <ModalBody className="pb-0">
            <FormInput
              name="profileName"
              labelLocaleKey="newClientPoolModal.presetName.title"
              rules={{ required: true }}
            />

            <label className="text-primary d-block mt-1 fw-medium mb-2">
              <FormattedMessage id="newClientPoolModal.baseMargins.title" />
            </label>

            <span className="text-secondary">
              <FormattedMessage id="newClientPoolModal.baseMargins.text" />
            </span>

            {productKey === 'algo-order-product' && (
              <>
                <div className="mt-3">
                  <AlgoOrderProductMarginUnitSwitch className="btn-block" />
                </div>

                <div className="mt-3">
                  <MarginLiquidityProvider showLabel={false} marginUnit={marginUnit()} />
                </div>
              </>
            )}
            {(productKey === 'limit-order-product' || productKey === 'fixing-order-product') && (
              <div className="mt-3">
                <MarginInput name="marginValue" unit={marginUnit()} />
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <button type="reset" className="btn btn-lg btn-flat-primary" onClick={props.onClosed}>
              <FormattedMessage id="common.cancel" />
            </button>

            <LoadingButton
              type="submit"
              className="btn btn-lg btn-primary btn-text-icon"
              loading={isLoading}
              dataE2e={`client-pool-preset-button-save`}
              disabled={Object.keys(errors).length > 0}
              icon={'arrow_forward'}
              iconPosition={'end'}
            >
              <FormattedMessage id="newClientPoolModal.add" />
            </LoadingButton>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default PresetOrderFormModal;
