import type { Action, Dispatch, Middleware, MiddlewareAPI, PayloadAction, createAction } from '@reduxjs/toolkit';
import type { Matcher } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { isDefined } from '@sgme/fp';
import { postClientApi, selectClientById, updateClientsApi } from 'services/clients';
import { actionCreators as ac } from 'store/actions';
import { selectors as sl } from 'store/selectors';

import { addFxOrderClientPoolPresetApi } from '@services/fx/addFxOrderClientPoolPreset';
import { renameProfileApi } from '@services/fx/renameFxProfile';
import { saveProductApi } from '@services/fx/saveEditedFxProfile';
import { updateMarginsApi } from '@services/marginProfiles';
import type { MarginProfileModifiedPayload } from '@store/clientEdition/clientEditionSlice';
import type { AppState } from '@store/store';
import { narrowDefined } from 'utils/asserts';
import { setPermissions } from '../authorization/authorization.slice';
import type { SgConnectUserInfo } from '../sgwtConnect/sgwtConnect.model';
import type { MatomoEvent } from './types';

export const analyticsMiddleware = (trackEvent: SgwtAnalyticsTrackEvent): Middleware<object, AppState> =>
  matchActions(
    trackEvent,
    // Authorization
    trackEventOnAction(setPermissions, (action: PayloadAction<SgConnectUserInfo>, state, dispatch) => {
      if (state.ui.isMatomoInitialized) {
        return undefined;
      }

      dispatch(ac.ui.matomoInitialized());

      return { category: 'Authentication', action: 'User connection' };
    }),

    // UI
    // TODO: called action?
    trackEventOnAction(ac.ui.pageChanged, (_action) => ({
      category: 'LAG',
      action: 'Page loaded',
    })),

    trackEventOnAction(ac.ui.authExpired, () => ({
      category: 'Authentication',
      action: 'Session timed out',
    })),

    // Filters
    trackEventOnAction(ac.filters.clientNameFilterChanged, (action: PayloadAction<string>) => ({
      category: 'LAG',
      action: 'Client search changed',
      name: action.payload,
    })),

    trackEventOnAction(ac.filters.activeClientsOnlyFilterToggled, () => ({
      category: 'LAG',
      action: 'Filter changed',
      name: 'Active clients only',
    })),

    trackEventOnAction(ac.filters.customizedMarginsOnlyFilterToggled, () => ({
      category: 'LAG',
      action: 'Filter changed',
      name: 'Customized margins only',
    })),

    trackEventOnAction(ac.filters.salesProfileFilterToggled, (action: PayloadAction<number>) => ({
      category: 'LAG',
      action: 'Filter changed',
      name: `Credit/Swap margin profile - ${action.payload}`,
    })),

    trackEventOnAction(ac.filters.devLProfileFilterToggled, (action: PayloadAction<number>) => ({
      category: 'LAG',
      action: 'Filter changed',
      name: `DEVL margin - ${action.payload}`,
    })),

    trackEventOnAction(ac.filters.pcruMarginFilterToggled, (action: PayloadAction<number>) => ({
      category: 'LAG',
      action: 'Filter changed',
      name: `PCRU margin - ${action.payload}`,
    })),

    trackEventOnAction(ac.filters.filtersCleared, () => ({
      category: 'LAG',
      action: 'Filters cleared',
    })),

    // Client edition
    trackEventOnAction(ac.clientEdition.toggleEdition, () => ({
      category: 'FX',
      action: 'Edition toggled',
    })),

    trackEventOnAction(
      ac.clientEdition.marginProfileModified,
      (action: PayloadAction<MarginProfileModifiedPayload>, state) => ({
        category: 'FX',
        action: 'Margin changed',
        name: `${narrowDefined(selectClientById(state)(action.payload.clientId)).name} - { ${Object.entries(
          action.payload.diff,
        )
          .map((x) => x.join(': '))
          .join(', ')} }`,
      }),
    ),

    // LAG
    trackEventOnAction(ac.marginProfileEdition.editionToggled, () => ({
      category: 'LAG',
      action: 'Edition toggled',
    })),

    trackEventOnAction(ac.marginProfileEdition.editionSaved, (_action, state) => ({
      category: 'LAG',
      action: 'Edition saved',
      name: `${sl.getMarginProfileEditionCellCount(state)} cells edited`,
    })),

    // ██████╗ ████████╗██╗  ██╗     ██████╗ ██╗   ██╗███████╗██████╗ ██╗   ██╗
    // ██╔══██╗╚══██╔══╝██║ ██╔╝    ██╔═══██╗██║   ██║██╔════╝██╔══██╗╚██╗ ██╔╝
    // ██████╔╝   ██║   █████╔╝     ██║   ██║██║   ██║█████╗  ██████╔╝ ╚████╔╝
    // ██╔══██╗   ██║   ██╔═██╗     ██║▄▄ ██║██║   ██║██╔══╝  ██╔══██╗  ╚██╔╝
    // ██║  ██║   ██║   ██║  ██╗    ╚██████╔╝╚██████╔╝███████╗██║  ██║   ██║
    // ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝     ╚══▀▀═╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝   ╚═╝

    // LAG

    trackEventOnApi(updateMarginsApi.endpoints.updateMargins.matchFulfilled, (_action, state) => ({
      category: 'LAG',
      action: 'Margins updated',
      name: JSON.stringify(_action.meta?.arg?.originalArgs),
    })),

    trackEventOnApi(updateClientsApi.endpoints.updateClients.matchFulfilled, (_action, state) => ({
      category: 'LAG',
      action: 'Clients saved',
      name: JSON.stringify(_action.meta?.arg?.originalArgs),
    })),

    trackEventOnApi(postClientApi.endpoints.postClient.matchFulfilled, (_action, state) => ({
      category: 'LAG',
      action: 'Client posted',
      name: JSON.stringify(_action.meta?.arg?.originalArgs),
    })),

    // FX

    trackEventOnApi(saveProductApi.endpoints.saveEditedFxProfile.matchFulfilled, (_action, state) => ({
      category: 'FX',
      action: 'Profile for product saved',
      name: JSON.stringify(_action.meta?.arg?.originalArgs),
    })),

    trackEventOnApi(renameProfileApi.endpoints.renameFxProfile.matchFulfilled, (_action, state) => ({
      category: 'FX',
      action: 'Profile renamed',
      name: JSON.stringify(_action.meta?.arg?.originalArgs),
    })),

    trackEventOnApi(
      addFxOrderClientPoolPresetApi.endpoints.addFxOrderClientPoolPreset.matchFulfilled,
      (_action, state) => ({
        category: 'FX',
        action:
          _action.meta?.arg?.originalArgs.profileTypeKey === 'custom-profile-type'
            ? 'Custom preset added'
            : 'Client pool preset added',
        name: JSON.stringify(_action.meta?.arg?.originalArgs),
      }),
    ),
  );

function matchActions(trackEvent: SgwtAnalyticsTrackEvent, ...matchers: ActionMatcher[]) {
  return function matomoMiddlewareFactory(api: MiddlewareAPI<Dispatch<Action>, AppState>) {
    return (next: Dispatch<Action>) => (action: Action) => {
      try {
        const dispatch = api.dispatch;
        const state = api.getState();

        for (const matcher of matchers) {
          if (matcher.match(action)) {
            const event = matcher.createEvent(action, state, dispatch);

            if (isDefined(event)) {
              trackEvent(event.category, event.action, event.name);
            }
          }
        }
      } finally {
        next(action);
      }
    };
  };
}

function trackEventOnAction(
  actionCreator: ReturnType<typeof createAction>,
  matomoEventCreator: (action: any, state: AppState, dispatch: Dispatch<Action<any>>) => MatomoEvent | undefined,
) {
  return {
    match: (action: Action) => actionCreator.type === action.type,
    createEvent: matomoEventCreator,
  };
}

function trackEventOnApi(
  apiMatcher: Matcher<any>,
  matomoEventCreator: (action: any, state: AppState) => MatomoEvent | undefined,
) {
  return {
    match: (action: Action) => apiMatcher(action),
    createEvent: matomoEventCreator,
  };
}

type ActionMatcher = ReturnType<typeof trackEventOnAction> | ReturnType<typeof trackEventOnApi>;

export type SgwtAnalyticsTrackEvent = (category: string, action: string, name?: string, value?: string) => void;
