import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LoadingButton } from 'components/LoadingButton';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { type ChangeEvent, useState } from 'react';
import { useAddFxCashClientPoolPresetMutation } from '@services/fx/addFxCashClientPoolPreset';
import {
  getInitialTieringColumns,
  INITIAL_CASH_MARGIN_COLUMN,
  initRows,
} from '@store/fxProfileEdition/fxProfileEditionSlice';
import { isDefined } from '@sgme/fp';
import type { FxCashProductKey, FxCashProfile } from '@services/fx/model/cash';
import { useDispatch } from 'react-redux';
import type { UpdateProfileErrorPayload } from '@services/fx/model/models';
import TechnicalError from '@components/modals/TechnicalError';

export interface PresetOrderFormModalProps extends ModalProps {
  profilName?: string;
}

export const PresetCashFormModal = ({ profileName, ...props }: PresetOrderFormModalProps) => {
  const { modalHeaderClose, onClosed } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newPresetName, setNewPresetName] = useState<string | undefined>(undefined);
  const { perimeterKey, productKey, gridDataType, clientBdrLevel, clientBdrId } = useFxUrlParams();
  const [error, setError] = useState<UpdateProfileErrorPayload['data']>({ errorCode: null, message: '' });

  const [addFxCashClientPoolPreset, { isLoading }] = useAddFxCashClientPoolPresetMutation();

  const onSubmit = async () => {
    const response = await addFxCashClientPoolPreset({
      perimeterKey: perimeterKey!,
      productKey: productKey!,
      profileTypeKey: 'client-pool-profile-type',
      payload: {
        profileName: newPresetName,
        columns:
          gridDataType === 'margin-grid-data-type'
            ? INITIAL_CASH_MARGIN_COLUMN
            : getInitialTieringColumns(productKey as FxCashProductKey),
      },
      queryParams: {
        gridDataType: gridDataType!,
        clientBdrLevel: clientBdrLevel!,
        clientBdrId: clientBdrId!,
      },
    });

    const { error, data } = response as { error: UpdateProfileErrorPayload; data: FxCashProfile };

    if (isDefined(error)) {
      setError(error.data);
    } else {
      if (isDefined(onClosed)) {
        onClosed();
      }
    }
    if (isDefined(data?.profileId)) {
      dispatch(
        initRows({
          rows: data.rows,
          //there is only one row for a newly created preset
          editingRow: { ...data.rows[0], uiRowId: 0, state: 'touched' },
          shouldKeepEditingRow: true,
        }),
      );
      navigate(`./edit?profileId=${data.profileId}`);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError({ errorCode: null, message: '' });
    setNewPresetName(event.target.value);
  };

  const reload = () => {
    window.location.reload();
  };

  const { errorCode, message } = error;

  return (
    <Modal data-e2e="preset-form-modal" contentClassName="border" {...props} style={{ minWidth: '600px' }}>
      <ModalHeader tag="h3" close={modalHeaderClose}>
        <FormattedMessage id="newClientPoolModal.title" />
      </ModalHeader>

      <ModalBody className="pb-0">
        <div className={`form-group`}>
          <label className="text-primary fw-medium mb-2" htmlFor="presetNameInput">
            <FormattedMessage id="newClientPoolModal.presetName.title" />
          </label>
          <input
            data-e2e="presetNameInput"
            type="text"
            autoComplete="off"
            className="form-control mt-8px"
            id="presetNameInput"
            value={newPresetName}
            onChange={onChange}
          />
        </div>
        {errorCode === 'ProfileNameExistError' && (
          <label className="text-danger d-block mt-1 fw-medium mb-2">
            <FormattedMessage id="error.ProfileNameExistError" />
          </label>
        )}
        {errorCode === 'TechnicalError' && <TechnicalError className={'mt-4px'} reload={reload} message={message} />}
      </ModalBody>

      <ModalFooter>
        <button type="reset" className="btn btn-lg btn-flat-primary" onClick={props.onClosed}>
          <FormattedMessage id="common.cancel" />
        </button>

        <LoadingButton
          className="btn btn-lg btn-primary"
          loading={isLoading}
          dataE2e={`client-pool-preset-button-save`}
          disabled={Boolean(errorCode) || !newPresetName}
          onClick={onSubmit}
          iconPosition={'end'}
          icon={'arrow_forward'}
        >
          <FormattedMessage id="newClientPoolModal.add" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export default PresetCashFormModal;
