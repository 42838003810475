import { FormattedMessage } from 'react-intl';
import { Authorize } from '@features/authorization/Authorize';
import type { ProfileId, Profiles, UpdateProfileErrorPayload } from '@services/fx/model/models';
import {
  getInitialTieringColumns,
  INITIAL_CASH_MARGIN_COLUMN,
  initRows,
} from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useAddFxCashClientPoolPresetMutation } from '@services/fx/addFxCashClientPoolPreset';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import type { FxCashProductKey, FxCashProfile } from '@services/fx/model/cash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isDefined } from '@sgme/fp';
import type { EditPermission } from '@features/authorization/authorization.model';
import { useState } from 'react';
import { ModalHandler } from '@components/modals/ModalHandler';
import { CustomCashFormButton } from '@pages/fx/FxSelectProfilePage/FxSelectableProfiles/ProfilesSelect/CustomProfileType/CustomCashProfileButton';

type CustomProfilPropsType = {
  height: number;
  availableProfilesOfTypeFiltered: Profiles[];
  radioGroup?: string;
  editedProfileId: number | undefined;
  onChange: (profileId: ProfileId) => void;
  authorizedPermissions: EditPermission[];
};

const CustomProfileType = (props: CustomProfilPropsType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, availableProfilesOfTypeFiltered, radioGroup, editedProfileId, onChange, authorizedPermissions } =
    props;
  const [addFxCashClientPoolPreset] = useAddFxCashClientPoolPresetMutation();
  const { perimeterKey, productKey, clientBdrId, clientBdrLevel, gridDataType } = useFxUrlParams();
  const [error, setError] = useState<UpdateProfileErrorPayload['data']>({ errorCode: null, message: '' });

  const onClick = async () => {
    const response = await addFxCashClientPoolPreset({
      perimeterKey: perimeterKey!,
      productKey: productKey!,
      profileTypeKey: 'custom-profile-type',
      payload: {
        columns:
          gridDataType === 'margin-grid-data-type'
            ? INITIAL_CASH_MARGIN_COLUMN
            : getInitialTieringColumns(productKey as FxCashProductKey),
      },
      queryParams: {
        gridDataType: gridDataType!,
        clientBdrLevel: clientBdrLevel!,
        clientBdrId: clientBdrId!,
      },
    });
    const { data, error } = response as { error: UpdateProfileErrorPayload; data: FxCashProfile };

    if (isDefined(error)) {
      setError(error.data);
    } else if (isDefined(data?.profileId)) {
      dispatch(
        initRows({
          rows: data.rows,
          //there is only one row for a newly created preset
          editingRow: { ...data.rows[0], uiRowId: 0, state: 'touched' },
          shouldKeepEditingRow: true,
        }),
      );
      navigate(`./edit?profileId=${data.profileId}`);
    }
  };

  const { errorCode, message } = error;

  return availableProfilesOfTypeFiltered.length > 0 ? (
    <ul className="list-group border overflow-auto" style={{ maxHeight: `${height}em` }}>
      {availableProfilesOfTypeFiltered.map((profile) => (
        <ProfileItem
          key={profile.profileId}
          profile={profile}
          radioGroup={radioGroup}
          onChange={onChange}
          editedProfileId={editedProfileId}
        />
      ))}
    </ul>
  ) : perimeterKey === 'fx-cash-rfq-perimeter' ? (
    <CustomCashFormButton
      onClick={onClick}
      authorizedPermissions={authorizedPermissions}
      errorCode={errorCode}
      message={message}
      setError={setError}
    />
  ) : (
    <CustomOrderFormButton />
  );
};

export default CustomProfileType;

type ProfileItemProps = {
  profile: any;
  radioGroup: string | undefined;
  onChange: (profileId: ProfileId) => void;
  editedProfileId: number | undefined;
};

const CustomOrderFormButton = () => (
  <Authorize authorizedFor={['FX_WRITEALLDESK']}>
    <ModalHandler modalName="CustomOrderFormModal">
      <button
        className="form-control mt-1 btn btn-md btn-outline-primary align-self-center"
        data-e2e="add-new-custom-margin"
      >
        <FormattedMessage id="button.newPreset.custom" />
      </button>
    </ModalHandler>
  </Authorize>
);

function ProfileItem(props: ProfileItemProps) {
  const {
    profile: { profileId, defaultProfile },
    radioGroup,
    onChange,
    editedProfileId,
  } = props;

  const onSelect = () => {
    onChange(profileId);
  };

  return (
    <label
      key={profileId}
      htmlFor={`${profileId}`}
      onClick={onSelect}
      className={`list-group-item list-group-item-action list-group-item-primary d-flex align-items-center justify-content-between border-bottom p-2 border-start-0 fw-medium ${
        defaultProfile ? 'text-info' : ''
      }`}
    >
      <FormattedMessage id="label.custom" />

      <input
        type="radio"
        id={`${profileId}`}
        value={profileId}
        name={radioGroup}
        checked={profileId === editedProfileId}
        onChange={onSelect}
      />
    </label>
  );
}
